<template>
	<div class="resultarea">
		<div class="rbox">
			<div style="text-align: center;white-space: pre-line;">{{cresult}}</div>
		</div>
		<div style="text-align: center;margin: 1rpx 3%;">
			按照不加百分号的百分比方式输入(原本：1557%→输入：1557)<br/>
			正常训练的话期末百分比填写100即可，所有项必填<br/>
			暂不清楚[PItem的50%加成]和[偶像之路的百分比加成]以及[55级饮料30%的加成]会有何种效果<br/>
			目前的先视为多种额外百分比均为独立乘区
		</div>
	</div>
	<div class="inputarea">
		<input class="textedit" type="text" placeholder="好印象层数" v-model="haobase"/>
		<input class="textedit" type="text" placeholder="卡片倍率" v-model="haoper"/>
		<input class="textedit" type="text" placeholder="期末百分比加成" v-model="perNum"/>
		<input class="textedit" type="text" placeholder="额外百分比加成(输入百分比总和)" v-model="experNum"/>
		<input class="submitb" type="button" value="开始计算" @click="caclresult"/>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				cresult:"结果将在此显示(将百分比转换为小数后输入)",
				haobase:null,
				haoper:null,
				perNum:null,
				experNum:null,
			};
		},
		methods:{
			caclresult(){
				//卡片造成的基础伤害
				var damage = Number(this.haobase) * Number(this.haoper/100);
				
				//回合结束后造成的基础伤害
				var turnover = Math.ceil(Number(this.haobase));
				//算上额外百分比
				var perList = this.experNum.split("+");
				for(var per of perList) {
					damage *= Number(per)/100 + 1;
					turnover *= Number(per)/100 + 1;
				}
				//取整操作
				damage = Math.ceil(damage);
				turnover = Math.ceil(turnover);
				//算上期末百分比
				var result = Math.ceil(damage * this.perNum/100);
				var turn_result = Math.ceil(turnover * this.perNum/100);
				this.cresult = "总计可造成："+result+"\n回合结束后造成："+turn_result;
			}
		}
	}
</script>

<style>
</style>
