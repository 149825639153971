<template>
  <div class="headbox" @click="backHome">回到首页</div>
  <div style="display: flex;flex-direction: column;"  v-if="pagedex==0">
    <Home></Home>
  </div>
  <div v-else-if="pagedex==1">
    <Sense></Sense>
  </div>
  <div v-else-if="pagedex==2">
    <Posion></Posion>
  </div>
  <div v-else-if="pagedex==3">
    <Shield></Shield>
  </div>
  <div v-else-if="pagedex==4">
    <Week></Week>
  </div>
  <div v-else-if="pagedex==5">
    <Oikomi></Oikomi>  
  </div>
  <div v-else>
    <div>页面加载失败</div>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import Home from './views/home.vue'
import Sense from './views/sensepage.vue'
import Posion from './views/poisonpage.vue'
import Shield from './views/shieldpage.vue'
import Oikomi from './views/oikomi.vue'
import Week from './views/week13.vue'
import Goaltos from './views/goaltos.vue'


export default {
  name: 'App',
  data(){
    return {
      pagedex:0,
    };
  },
  mounted(){
    document.title = "Gakumas计算器";
  },
  components: {
    HelloWorld,Home,Sense,Posion,Shield,Oikomi,Week,Goaltos
  },
  methods:{
    backHome(){
      this.pagedex = 0;
    },
    changePage(num){
      this.pagedex = num;
    }
  }
}
</script>

<style>
#app{
  text-align: center;
}

.headbox{
  text-align: center;
  height: 35px;
  line-height: 35px;
  color:white;
  background-image: linear-gradient(to right, #4B8CFF, #1FD2FF);
  border-radius: 10px;
  margin-bottom: 40px;
}

/*PC端*/
@media screen and (min-width: 768px){
  #app{
    margin-left: 20%;
    margin-right: 20%;
  }
  .submitb{
    width: 60%;
    height: 80px;
  }
  .rbox{
    width: 750px;
    height: 375px;
  }
	.sanedit{
		margin: 10px;
		height: 30px;
		padding: 10px;
		border-radius: 30px;
	}
  .wbox{
    flex-direction: row;
  }
  .card{
    width: 30%;
  }
}

/* 手机端 */
@media screen and (max-width: 767px) {
  .rbox{
    width: 300px;
    height: 150px;
  }
  .submitb{
    width: 50%;
    height: 50px;
  }
  .sanedit{
    margin: 3px;
		height: 16px;
		padding: 3px;
		border-radius: 10px;
  }
  .wbox{
    flex-direction: column;
  }
  .card{
    width: 60%;
  }
  .sanedit{
    height: 30px;
  }
}

@media (prefers-color-scheme: dark) {
  .rbox{
    color:#d4bcbc;
  }
}

/*输入框*/
.textedit{
  width: 80%;
  border: 0px;
  border-radius: 10px;
  padding: 15px 20px;
  border:1px solid #e1dcdc;
  background-color: #f1f1f1;
  margin: 20px auto;
  background-image: linear-gradient(to bottom right, #defaff, #defffa );
}
.textedit::placeholder{
  color:rgb(106, 114, 114);
}

/*计算按钮*/
.submitb{
  border:0px solid #d0e6f1;
  text-align: center;
  color: white;
  border-radius: 10px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(to right, #4B8CFF, #1FD2FF);
  margin: 20px auto;
}

/*结果区域*/
.resultarea{
  width: 100%;
  align-items: center;
  text-align: center;
}

/*结果展示框*/
.rbox{
  background-image: url("./assets/back.png");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  margin: 50px auto;
  color:#375a4b;
}

.sanicon{
  width: 100px;
  height: 100px;
  margin: 10px auto;
}
	
.wbox{
  width: 100%;
  display: flex;
}

.card{
  margin: 20px auto;
  text-align: center; /* 图片和输入框居中显示 */
  display: flex;
  flex-direction: column;
}
/*三维编辑区*/
.sanedit{
  border:1px solid #e1dcdc;
  text-align: center;
}

</style>
