<template>
	<div class="wbox">
		<div class="card">
			<img style="justify-content: center;" class="sanicon" src="../assets/sanwei/vo.png"/>
			<input v-model="von" class="sanedit" type="text" placeholder="基础值" style="background-color: #f4e3e5;"/>
			<input v-model="voper" class="sanedit" type="text" placeholder="加成值" style="background-color: #f4e3e5;"/>
			<input type="checkbox" @click="statechange('vo')"/>SP课程
		</div>
		<div class="card">
			<img class="sanicon" src="../assets/sanwei/da.png"/>
			<input v-model="dan" class="sanedit" type="text" placeholder="基础值" style="background-color: #d4e1f4;"/>
			<input v-model="daper" class="sanedit" type="text" placeholder="加成值" style="background-color: #d4e1f4;"/>
			<input type="checkbox" @click="statechange('da')"/>SP课程
		</div>
		<div class="card">
			<img class="sanicon" src="../assets/sanwei/vi.png"/>
			<input v-model="vin" class="sanedit" type="text" placeholder="基础值" style="background-color: #f4f2cf;"/>
			<input v-model="viper" class="sanedit" type="text" placeholder="加成值" style="background-color: #f4f2cf;"/>
			<input type="checkbox" @click="statechange('vi')"/>SP课程
		</div>
	</div>
	<div style="text-align: center;">注：14周因外出和休息的加分事件不参与此次计算<br/>结果的格式为【训练后】→【追加后】→【期末后】<br/>期末追加的详细信息可前往期末追加计算</div>
	<div style="text-align: center;">
		<div class="resultarea">
			<div class="rbox" style="background-color: #f4e3e5;">
				<div style="text-align: center;white-space: pre-line;">{{vo}}</div>
			</div>
		</div>
		<div class="resultarea">
			<div class="rbox" style="background-color: #d4e1f4;">
				<div style="text-align: center;white-space: pre-line;">{{da}}</div>
			</div>
		</div>
		<div class="resultarea">
			<div class="rbox" style="background-color: #f4f2cf;">
				<div style="text-align: center;white-space: pre-line;">{{vi}}</div>
			</div>
		</div>
	</div>
	<input class="submitb" type="button" value="开始计算" @click="weekcacl"/>
</template>

<script>
	export default {
		data() {
			return {
				von:null,
				vosp:false,
				voper:null,
				dan:null,
				dasp:false,
				daper:null,
				vin:null,
				visp:false,
				viper:null,
				vo:"选红结果将在此展示",
				da:"选蓝结果将在此展示",
				vi:"选黄结果将在此展示"
			};
		},
		methods:{
			statechange(name){
				if(name == "vo"){
					this.vosp = !this.vosp;
				}else if(name == "da"){
					this.dasp = !this.dasp;
				}else{
					this.visp = !this.visp;
				}
			},
			add(basenum,pernum,addnum,max){
				var result = addnum*(1+pernum/100)+basenum;
				if(result>max){
					return max;
				}
				return Math.floor(result);
			},
			cacleonce(vo,da,vi,voa,daa,via){
				var origin = Number(vo)+Number(da)+Number(vi);
				var rred = this.add(Number(vo),Number(this.voper),voa,1500);
				var rblue = this.add(Number(da),Number(this.daper),daa,1500);
				var ryellow = this.add(Number(vi),Number(this.viper),via,1500);
				var target = rred + rblue + ryellow;
				var finalscore = Math.min(rred+30,1500) + Math.min(rblue+30,1500) + Math.min(ryellow+30,1500);
				return [origin,target,finalscore,rred,rblue,ryellow];
			},
			caclresult(vo,da,vi){
				//追红
				var rlist = this.cacleonce(vo,da,vi,310,145,145);
				//追蓝
				var blist = this.cacleonce(vo,da,vi,145,310,145);
				//追黄
				var ylist = this.cacleonce(vo,da,vi,145,145,310);
				return [rlist[0],rlist[1],rlist[2],blist[0],blist[1],blist[2],ylist[0],ylist[1],ylist[2]]
			},
			weekcacl(){
				var before_torena = Number(this.von) + Number(this.dan) +Number(this.vin);
				//选红
				var red_result = this.add(Number(this.von),Number(this.voper),this.vosp?220:150,1500);
				var red_list = this.caclresult(red_result,Number(this.dan),Number(this.vin));
				this.vo = `训练前分数：${before_torena} 
				13周选红，后续期末追加
				追红：${red_list[0]}→${red_list[1]}→${red_list[2]}
				追蓝：${red_list[3]}→${red_list[4]}→${red_list[5]}
				追黄：${red_list[6]}→${red_list[7]}→${red_list[8]}`
				//选蓝
				var blue_result = this.add(Number(this.dan),Number(this.daper),this.dasp?220:150,1500);
				var blue_list = this.caclresult(Number(this.von),blue_result,Number(this.vin));
				this.da = `训练前分数：${before_torena} 
				13周选蓝，后续期末追加
				追红：${blue_list[0]}→${blue_list[1]}→${blue_list[2]}
				追蓝：${blue_list[3]}→${blue_list[4]}→${blue_list[5]}
				追黄：${blue_list[6]}→${blue_list[7]}→${blue_list[8]}`
				//选红
				var yellow_result = this.add(Number(this.vin),Number(this.viper),this.visp?220:150,1500);
				var yellow_list = this.caclresult(Number(this.von),Number(this.dan),yellow_result);
				this.vi = `训练前分数：${before_torena} 
				13周选黄，后续期末追加
				追红：${yellow_list[0]}→${yellow_list[1]}→${yellow_list[2]}
				追蓝：${yellow_list[3]}→${yellow_list[4]}→${yellow_list[5]}
				追黄：${yellow_list[6]}→${yellow_list[7]}→${yellow_list[8]}`
			}
		}
	}
</script>

<style>
	
</style>
